var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-card-title',[_vm._v(" Projekt und Baustatus ändern ")]),_c('ProjectNameSelection',{attrs:{"projectItem":_vm.projectItem},on:{"updateProjektItem":_vm.updateProjektItem}})],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.projectItem || _vm.projectItem === undefined},on:{"click":_vm.getFtthAddressesByProject}},[_vm._v(" Projekt laden ")])],1)],1)],1),(
      !_vm.changeRequestFlag &&
        !_vm.loadingPropertyStatus &&
        !_vm.loadingAddresses &&
        _vm.data &&
        _vm.data.length
    )?_c('v-row',{staticStyle:{"padding-top":"15px"}},[_c('v-col',[_c('v-tabs',[_c('v-tab',{on:{"change":function () {
              _vm.projectTabSelected = false;
              _vm.propertyTabSelected = true;
            }}},[_vm._v(" Liegenschaftsstatus ")]),(
            _vm.userGroups.includes('LgSpotBau') ||
              _vm.userGroups.includes('LgSpotBauLeitung') ||
              _vm.userGroups.includes('camunda-admin')
          )?_c('v-tab',{on:{"change":function () {
              _vm.projectTabSelected = true;
              _vm.propertyTabSelected = false;
            }}},[_vm._v(" Projektstatus ")]):_vm._e()],1)],1)],1):_vm._e(),(
      !_vm.changeRequestFlag &&
        !_vm.loadingPropertyStatus &&
        !_vm.loadingAddresses &&
        _vm.data &&
        _vm.data.length
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.propertyTabSelected)?_c('div',[_c('v-row',[_c('v-col',[(
                _vm.userGroups.includes('LgSpotBau') ||
                  _vm.userGroups.includes('LgSpotBauLeitung') ||
                  _vm.userGroups.includes('LgSpotAccess') ||
                  _vm.userGroups.includes('LgSpotAuftragsmanagement') ||
                  _vm.userGroups.includes('camunda-admin')
              )?_c('v-checkbox',{attrs:{"id":"Hausanschluss gebaut","multiple":"","color":"primary","value":"Hausanschluss gebaut"},on:{"change":function($event){_vm.houseconnectionBuild = !_vm.houseconnectionBuild}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.houseconnectionBuild ? '#6aa84f' : '#757575'}},[_vm._v(" mdi-home-alert ")]),_vm._v(" Hausanschluss gebaut ")]},proxy:true}],null,false,2455076278),model:{value:(_vm.propertyState),callback:function ($$v) {_vm.propertyState=$$v},expression:"propertyState"}}):_vm._e()],1),_c('v-col',[(
                _vm.userGroups.includes('LgSpotFieldservice') ||
                  _vm.userGroups.includes('LgSpotAccess') ||
                  _vm.userGroups.includes('LgSpotBauLeitung') ||
                  _vm.userGroups.includes('LgSpotAuftragsmanagement') ||
                  _vm.userGroups.includes('camunda-admin')
              )?_c('v-checkbox',{attrs:{"id":"Haus gepatched","multiple":"","color":"primary","value":"Haus gepatched"},on:{"change":function($event){_vm.housePatched = !_vm.housePatched}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.housePatched ? '#6aa84f' : '#757575'}},[_vm._v(" mdi-home-assistant ")]),_vm._v(" Haus gepatched ")]},proxy:true}],null,false,3118444352),model:{value:(_vm.propertyState),callback:function ($$v) {_vm.propertyState=$$v},expression:"propertyState"}}):_vm._e()],1),_c('v-col',[(
                _vm.userGroups.includes('LgSpotFieldservice') ||
                  _vm.userGroups.includes('LgSpotAccess') ||
                  _vm.userGroups.includes('LgSpotAuftragsmanagement') ||
                  _vm.userGroups.includes('camunda-admin')
              )?_c('v-checkbox',{attrs:{"id":"POP Online","multiple":"","color":"primary","value":"POP Online"},on:{"change":function($event){_vm.popOnline = !_vm.popOnline}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.popOnline ? '#6aa84f' : '#757575'}},[_vm._v(" mdi-transit-connection-variant ")]),_vm._v(" POP Online ")]},proxy:true}],null,false,1743098841),model:{value:(_vm.propertyState),callback:function ($$v) {_vm.propertyState=$$v},expression:"propertyState"}}):_vm._e()],1)],1)],1):_vm._e(),(_vm.projectTabSelected)?_c('div',[_c('v-row',[_c('v-col',{staticStyle:{"max-width":"4.333%"},attrs:{"cols":"1","align-self":"center"}},[_c('v-icon',[_vm._v(" mdi-home-group ")])],1),_c('v-col',[(_vm.projectTabSelected)?_c('v-combobox',{attrs:{"items":_vm.projectStates,"item-disabled":"disable","item-text":"name","color":"primary","label":"Projektstatus wählen..."},model:{value:(_vm.newProjectState),callback:function ($$v) {_vm.newProjectState=$$v},expression:"newProjectState"}}):_vm._e()],1),_c('v-col',[_c('DatePicker',{attrs:{"label":"Datum für den Baustart","value":_vm.showConstructionStartDate,"disableWeekend":"","disableHolidays":"","disabled":_vm.newProjectState.name !=
                  _vm.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value},on:{"input":function($event){return _vm.updateConstructionStartDate($event)}}})],1)],1)],1):_vm._e(),_c('DetailMap',{attrs:{"data":_vm.filteredData,"optionalData":_vm.propertiesWithoutProject,"projectId":_vm.projectItem.projektId,"height":"539px"}}),(
          _vm.propertiesWithoutProject != undefined &&
            _vm.propertiesWithoutProject !== null &&
            _vm.propertiesWithoutProject.length > 0
        )?_c('div',[_c('v-alert',{staticClass:"alert-margin",attrs:{"dense":"","type":"error"}},[_vm._v(" Einige Adressen mit einem Dienstauftrag haben keine Projektzuordnung "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.toggleTable = !_vm.toggleTable}}},[(_vm.toggleTable)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)],1),(_vm.toggleTable)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchTextWithoutProject),callback:function ($$v) {_vm.searchTextWithoutProject=$$v},expression:"searchTextWithoutProject"}}):_vm._e(),(_vm.toggleTable)?_c('v-data-table',{staticClass:"elevation-1 without-project-table",staticStyle:{"background":"rgba(255, 0, 0, 0.05)"},attrs:{"headers":_vm.propertiesWithoutProjectHeaders,"items":_vm.propertiesWithoutProject,"item-class":_vm.row_classes,"multi-sort":"","search":_vm.searchTextWithoutProject},scopedSlots:_vm._u([{key:"item.geo",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(item.geo == undefined || item.geo === null)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#8800b8"}},'v-icon',attrs,false),on),[_vm._v(" mdi-map-marker-off-outline ")]):(item.hasDifferentProject === true)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#bdbd00"}},'v-icon',attrs,false),on),[_vm._v(" mdi-map-marker-alert-outline ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-map-marker-check-outline ")])]}}],null,true)},[(item.geo == undefined || item.geo === null)?_c('span',[_vm._v(" Keine Ags27 ID vorhanden ")]):(item.hasDifferentProject === true)?_c('span',[_vm._v(" Liegenschaft ist dem Projekt "+_vm._s(item.projectId)+" zugeordnet")]):_c('span',[_vm._v("Projektzuordnung fehlt")])])]}},{key:"item.address.street",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.address.street)+" "+_vm._s(item.address.houseNumber)+_vm._s(item.address.houseNumberSupplement)+" ")]}},{key:"item.address.firstName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.address.lastName)+", "+_vm._s(item.address.firstName)+" ")]}},{key:"item.ftthProductDescription",fn:function(props){return [(props.item.ftthProductDescription.length === 1)?[_vm._v(" "+_vm._s(props.item.ftthProductDescription[0])+" ")]:_vm._l((props.item
                  .ftthProductDescription),function(description,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(description))])])})]}}],null,true)}):_vm._e()],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-data-table',{ref:"dataTable",staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.data,"item-key":"ags27Id","item-class":_vm.row_classes,"single-expand":false,"show-expand":false,"expanded":_vm.expandedRows,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.searchText,"show-select":_vm.propertyTabSelected,"checkbox-color":"primary","items-per-page":25,"footer-props":{
          showFirstLastPage: true,
          showCurrentPage: true,
          'items-per-page-text': 'Zeilen pro Seite:',
          'items-per-page-options': [10, 25, 50, 100, -1],
          'page-text': '{0}-{1} von {2}'
        }},on:{"update:expanded":function($event){_vm.expandedRows=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"toggle-select-all":function () {
            !_vm.selectAllToggle
              ? (_vm.checkedAddresses = _vm.filteredData)
              : (_vm.checkedAddresses = []);
            _vm.selectAllToggle = !_vm.selectAllToggle;
          },"current-items":_vm.getFiltered,"click:row":_vm.rowClicked,"input":function($event){return _vm.getSelectedItems($event)}},scopedSlots:_vm._u([(_vm.propertyTabSelected)?{key:"footer.page-text",fn:function(){return [_vm._v(" Aktuell "+_vm._s(_vm.checkedAddresses.length)+" Adressen ausgewählt von "+_vm._s(_vm.data.length)+" ")]},proxy:true}:null,{key:"item.address.houseNumber",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.address.houseNumber)+_vm._s(item.address.houseNumberSupplement)+" ")]}},{key:"item.constructionState",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.houseConnectionBuild ? '#6aa84f' : '#9b3232'}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-alert ")])]}}],null,true)},[(item.houseConnectionBuild)?_c('span',[_vm._v("Hausanschluss gebaut")]):_c('span',[_vm._v("Hausanschluss nicht gebaut")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.houseConnectionPatched ? '#6aa84f' : '#9b3232'}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-assistant ")])]}}],null,true)},[(item.houseConnectionPatched)?_c('span',[_vm._v("Haus gepatched")]):_c('span',[_vm._v("Haus nicht gepatched")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.popOnline ? '#6aa84f' : '#9b3232'}},'v-icon',attrs,false),on),[_vm._v(" mdi-transit-connection-variant ")])]}}],null,true)},[(item.popOnline)?_c('span',[_vm._v("POP Online")]):_c('span',[_vm._v("POP nicht Online")])])]}},{key:"expanded-item",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"spot-expanded-table-padding",attrs:{"colspan":"7"}},[_c('v-data-table',{staticClass:"spot-expanded-table-shadow",attrs:{"headers":_vm.expandedTableHeaders,"items":item.contractList,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.ftth",fn:function(props){return [(props.item.ftth && props.item.hc)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-home-plus ")])]}}],null,true)},[_c('span',[_vm._v("Dienstvertrag mit Hausanschluss")])])]:_vm._e(),(props.item.ftth && !props.item.hc)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-web ")])]}}],null,true)},[_c('span',[_vm._v("Dienstvertrag")])])]:_vm._e(),(props.item.hc && !props.item.ftth)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-home-city ")])]}}],null,true)},[_c('span',[_vm._v("Hausanschluss")])])]:_vm._e()]}},{key:"item.productDescription",fn:function(props){return [(props.item.productDescription.length === 1)?[_vm._v(" "+_vm._s(props.item.productDescription[0])+" ")]:_vm._l((props.item
                      .productDescription),function(description,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(description))])])})]}}],null,true)})],1)]}}],null,true),model:{value:(_vm.checkedAddresses),callback:function ($$v) {_vm.checkedAddresses=$$v},expression:"checkedAddresses"}}),(_vm.data && _vm.data.length)?_c('div',{staticClass:"buttons-wrapper"},[(_vm.propertyTabSelected)?_c('v-btn',{attrs:{"disabled":_vm.checkedAddresses.length === 0 || _vm.propertyState.length <= 0,"color":"primary","large":""},on:{"click":_vm.changeRequestOverview}},[_vm._v(" Status ändern ")]):_vm._e(),(_vm.projectTabSelected)?_c('v-btn',{attrs:{"disabled":_vm.newProjectState == undefined ||
              _vm.newProjectState.name === _vm.currentProjectState.name ||
              (_vm.newProjectState.name ===
                _vm.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value &&
                (_vm.constructionStartDate == null ||
                  _vm.constructionStartDate === undefined)),"color":"primary","large":""},on:{"click":_vm.createProjectChangeRequest}},[_vm._v(" Status ändern ")]):_vm._e()],1):_vm._e()],1)],1):_vm._e(),(
      _vm.checkedAddresses &&
        _vm.checkedAddresses.length &&
        _vm.changeRequestFlag &&
        _vm.propertyTabSelected
    )?[_c('v-data-table',{staticClass:"elevation-1",attrs:{"caption":_vm.caption,"headers":_vm.tableHeaders,"items":_vm.checkedAddresses,"item-key":"ags27Id","item-class":_vm.row_classes,"single-expand":false,"show-expand":false,"search":_vm.searchText,"expanded":_vm.expandedRows,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":25,"footer-props":{
        showFirstLastPage: true,
        showCurrentPage: true,
        'items-per-page-text': 'Zeilen pro Seite:',
        'items-per-page-options': [10, 25, 50, 100, -1]
      }},on:{"update:expanded":function($event){_vm.expandedRows=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_vm._v(" "+_vm._s(_vm.checkedAddresses.length)+" von "+_vm._s(_vm.data.length)+" Adressen ")]},proxy:true},{key:"item.address.houseNumber",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.address.houseNumber)+_vm._s(item.address.houseNumberSupplement)+" ")]}},{key:"item.constructionState",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.houseConnectionBuild ? '#6aa84f' : '#9b3232'}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-alert ")])]}}],null,true)},[(item.houseConnectionBuild)?_c('span',[_vm._v("Hausanschluss gebaut")]):_c('span',[_vm._v("Hausanschluss nicht gebaut")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.houseConnectionPatched ? '#6aa84f' : '#9b3232'}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-assistant ")])]}}],null,true)},[(item.houseConnectionPatched)?_c('span',[_vm._v("Haus gepatched")]):_c('span',[_vm._v("Haus nicht gepatched")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.popOnline ? '#6aa84f' : '#9b3232'}},'v-icon',attrs,false),on),[_vm._v(" mdi-transit-connection-variant ")])]}}],null,true)},[(item.popOnline)?_c('span',[_vm._v("POP Online")]):_c('span',[_vm._v("POP nicht Online")])])]}},{key:"expanded-item",fn:function(ref){
      var item = ref.item;
return [_c('td',{staticClass:"spot-expanded-table-padding",attrs:{"colspan":"7"}},[_c('v-data-table',{staticClass:"spot-expanded-table-shadow",attrs:{"headers":_vm.expandedTableHeaders,"items":item.contractList,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.ftth",fn:function(props){return [(_vm.rops.item.ftth && props.item.hc)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-home-plus ")])]}}],null,true)},[_c('span',[_vm._v("Dienstvertrag mit Hausanschluss")])])]:_vm._e(),(props.item.ftth && !props.item.hc)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-web ")])]}}],null,true)},[_c('span',[_vm._v("Dienstvertrag")])])]:_vm._e(),(props.item.hc && !props.item.ftth)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-home-city ")])]}}],null,true)},[_c('span',[_vm._v("Hausanschluss")])])]:_vm._e()]}},{key:"item.productDescription",fn:function(props){return [(props.item.productDescription.length === 1)?[_vm._v(" "+_vm._s(props.item.productDescription[0])+" ")]:_vm._l((props.item
                    .productDescription),function(description,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(description))])])})]}}],null,true)})],1)]}}],null,true)}),_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{staticStyle:{"position":"absolute"},attrs:{"color":"primary"},on:{"click":_vm.changeRequestOverview}},[_vm._v(" Zurück ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createPropertyChangeRequest()}}},[_vm._v(" Abschließen ")])],1)]:_vm._e(),(_vm.loadingPropertyStatus || _vm.loadingAddresses)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"size":120,"color":"primary","indeterminate":""}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }